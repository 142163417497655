<template>
  <div class="flasher-wrapper">
    <h1>RedPill Loader</h1>
    <div class="flasher-container">
      <PrimeButton v-if="state === 'standby'" label="Load MicroPython" class="p-button-raised p-button-danger" @click="loadMicroPython" />
      <h3 v-if="state === 'connecting'">Connecting</h3>
      <ProgressBar v-if="state === 'connecting'" mode="indeterminate" class="bar-connecting" />
      <h3 v-if="state === 'erasing'">Erasing</h3>
      <ProgressBar v-if="state === 'erasing'" :value="eraseProgress" class="bar-erasing" />
      <h3 v-if="state === 'writing'">Writing</h3>
      <ProgressBar v-if="state === 'writing'" :value="writeProgress" class="bar-writing" />
    </div>
  </div>
</template>

<script>
import { Transport } from '@/js/webserial.js'
import { ESPLoader } from '@/js/ESPLoader.js'

const CryptoJS = require("crypto-js");
import pako from "pako";
import {Logger} from "@/js/logger";

const logger = new Logger('ESPLoader.js');

export default {
  name: 'RedPillFlasher',
  components: {

  },
  data() {
    return {
      loadedData: null,
      device: null,
      transport: null,
      esploader: null,
      chip: null,
      connected: false,
      eraseProgress: 0,
      writeProgress: 0,
      state: 'standby',
      toast: null,
    }
  },
  methods: {
    async loadMicroPython () {
      let self = this;
      await self.loadRemote();
    },
    async loadRemote () {
      this.eraseProgress = 0;
      this.writeProgress = 0;
      logger.log('Loading bootloader', 53);
      await this.loadBootloader();
      logger.log('Connecting device', 55);
      this.state = 'connecting';
      if (!await this.connectDevice()) {
        this.state = 'standby';
        return;
      }
      logger.log('Checking device');
      const isRedPill = await this.checkRedPill();
      this.confirmRedPill(isRedPill);
    },
    async eraseAndWrite () {
      await this.logAction('start');
      logger.log('Erasing flash');
      this.state='erasing';
      await this.eraseFlash();
      logger.log('Writing bootloader');
      this.state='writing';
      await this.writeBootloader();
      logger.log('Resetting');
      //await this.resetChip();
      // await this.esploader.hard_reset();
      await this.transport.setRTS(true);
      await this.esploader._sleep(100);
      await this.resetChip();
      logger.log('Disconnecting');
      await this.disconnectChip();
      logger.log('Ended.');
      this.$toast.add({severity:'success', summary: 'MicroPython Loaded', detail:'If uploading Python files doesn\'t work, please disconnect and reconnect the RedPill.'});
      this.state = 'standby';
      await this.logAction('end');
    },
    async cancelWrite () {
      let self = this;
      logger.log('Disconnecting');
      await self.disconnectChip();
      logger.log('Ended.');
      this.$toast.add({severity:'info', summary: 'MicroPython Loading Canceled', detail:'The MicroPython loading was canceled.', life: 5000});
      this.state = 'standby';
      await this.logAction('cancel');
    },
    loadBootloader () {
      let self = this;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', 'GENERIC_S3_SPIRAM-20220618-v1.19.1.bin', true);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function(e) {
        // response is unsigned 8 bit integer
        if (e.currentTarget.status === 200) {
          var responseArray = new Uint8Array(this.response);
          self.loadedData = self.convertUint8ArrayToBinaryString(responseArray);
        } else {
          self.$toast.add({severity:'error', summary: 'MicroPython Loading Error', detail:'Error loading bootloader file.', life: 5000});
          self.state = 'standby';
        }
      };

      xhr.send();
    },
    convertUint8ArrayToBinaryString (u8Array) {
      var i, len = u8Array.length, b_str = "";
      for (i=0; i<len; i++) {
        b_str += String.fromCharCode(u8Array[i]);
      }
      return b_str;
    },
    async connectDevice () {
      let self = this;
//    device = await navigator.usb.requestDevice({
//        filters: [{ vendorId: 0x10c4 }]
//    });

      if (this.device === null) {
        try {
          this.device = await navigator.serial.requestPort({
          });
        } catch (e) {
          logger.log(e);
          self.$toast.add({severity:'error', summary: 'MicroPython Loading Error', detail:'No port selected or error opening serial port.', life: 5000});
          this.state = 'standby';
          return false;
        }

        this.transport = new Transport(this.device);
      }

      try {
        self.esploader = new ESPLoader(self.transport, "921600", pako);

        logger.log('ESP Initialised.');

        self.chip = await self.esploader.main_fn();

        logger.log("chip", 145);
        logger.log(self.chip, 146);
        logger.log(self.esploader.macAddress, 147);

        // Temporarily broken
        // await esploader.flash_id();

        self.connected = true;
      } catch(e) {
        self.connected = false;
        self.$toast.add({severity:'error', summary: 'MicroPython Loading Error', detail:'Could not identify chip. Please check if the right port is selected and the port is not in use by another application (Eg. Arduino IDE).', life: 5000});
        self.state = 'standby';
        logger.log(e);
        logger.log(`Error: ${e.message}`);
        this.device = null;
        return false;
      }

      logger.log("Settings done for: " + self.chip);
      return true;
    },
    async checkRedPill () {
      let self = this;
      await logger.log('Checking ' + self.chip + ' mac ' + this.esploader.macAddress);
      try {
        const result = await fetch('/redpill', {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({'mac': self.esploader.macAddress, 'type': self.chip, 'action': 'check'}) // body data type must match "Content-Type" header
        });
        const dataResult = await result.json();
        await  logger.log(result, 187);
        await logger.log(dataResult, 189);
        return dataResult.result;
      } catch (e) {
        self.$toast.add({severity:'error', summary: 'RedPill Checking Error', detail:'Could not check for RedPill modele. Service might be down.', life: 5000});
        return false;
      }
    },
    async logAction (action) {
      let self = this;
      await logger.log('Log action ' + self.chip + ' mac ' + this.esploader.macAddress);
      try {
        const result = await fetch('/log', {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({'mac': self.esploader.macAddress, 'type': self.chip, 'action': action}) // body data type must match "Content-Type" header
        });
        const dataResult = await result.json();
        await  logger.log(result, 213);
        await logger.log(dataResult, 214);
        return dataResult.result;
      } catch (e) {
        logger.log('Error logging.', 217);
        return false;
      }
    },
    async resetChip () {
      if (this.device === null) {
        this.device = await navigator.serial.requestPort({
        });
        this.transport = new Transport(this.device);
      }

      await this.transport.setDTR(false);
      await new Promise(resolve => setTimeout(resolve, 100));
      await this.transport.setDTR(true);
    },
    async setSerialPort() {
      let self = this;
      if (this.device === null) {
        logger.log('Device is null');
        try {
          logger.log('Requesting port')
          this.device = await navigator.serial.requestPort({
          });

          logger.log('Device data');
          logger.log(this.device);

          logger.log('Setting connect listener');
          this.device.addEventListener('connect', (event) => {
            logger.log('Serial connected');
            logger.log(event);
          });
          logger.log('Setting disconnect listener');
          this.device.addEventListener('disconnect', (event) => {
            logger.log('Serial disconnected');
            logger.log(event);
          });
          logger.log('finish getting port');
        } catch (e) {
          logger.log('Exception requesting port');
          logger.log(e);

          self.$toast.add({severity:'error', summary: 'MicroPython Loading Error', detail:'Exception requesting port.', life: 5000});
          self.state = 'standby';
          return false;
        }

        logger.log('setting transport')
        this.transport = new Transport(this.device);
      }
    },
    async eraseFlash () {
      await this.logAction('erase');
      let self = this;
      let eraseProgress = 0;
      let timer = setInterval(() => {
        eraseProgress++;
        if (eraseProgress > 100) {
          eraseProgress = 100;
        }
        logger.log(eraseProgress);
        self.eraseProgress = eraseProgress;
      }, 250);
      try{
        await self.esploader.erase_flash();
      } catch (e) {
        logger.log(e);
        logger.log(`Error: ${e.message}`);
      } finally {
        logger.log('Erased.');
        clearInterval(timer);
        logger.log('100%');
      }
    },

    cleanup () {
      this.device = null;
      this.transport = null;
      this.chip = null;
    },

    async writeBootloader () {
      await this.logAction('write');
      let self = this;
      // Hide error message
      const fileArray = [];

      fileArray.push({data:self.loadedData, address:0});

      try {
        await self.esploader.write_flash({
          fileArray,
          flash_size: 'keep',
          reportProgress(fileIndex, written, total) {
            let writeProgress = Math.round(written / total * 100);
            logger.log(writeProgress + '%');
            self.writeProgress = writeProgress;
          },
          calculateMD5Hash: (image) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
        });
        await self.esploader.hard_reset();
      } catch (e) {
        logger.log(e);
        logger.log(`Error: ${e.message}`);
      } finally {
        logger.log('finally');
      }
    },
    async disconnectChip () {
      logger.log('disconnectChip');
      if(this.transport) {
        logger.log('transport set, disconnecting');
        await this.transport.disconnect();
      }
      this.connected = false;
    },
    async resetVars () {
      this.connected = false;
      this.device = null;
      this.transport = null;
      this.chip = null;
    },
    confirmRedPill(isRedPill) {
      const message = isRedPill ? 'RedPill module detected. Proceed with writing the bootloader?' : 'RedPill module was not detected. There is no guarantee that the bootloader will work. Continue to write the bootloader at your own risk?'

      this.$confirm.require({
        message: message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          setTimeout(this.eraseAndWrite, 1000);
        },
        reject: () => {
          setTimeout(this.cancelWrite, 1000);
        },
        onShow: () => {
          //callback to execute when dialog is shown
        },
        onHide: () => {
          //callback to execute when dialog is hidden
        }
      });
    },
  },
  mounted() {
    this.$toast.add({severity:'info', summary: 'RedPill Loader', detail:'This tool is made especially for the RedPill series. Using it with other ESP32 based modules/kits might work, but you do this on your own risk. I can\'t give support for other modules.'});
    this.$toast.add({severity:'warn', summary: 'RedPill Loader', detail: 'During the process, the browser might become unresponsive for about two minutes!'});
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.bar-connecting.p-progressbar .p-progressbar-value {
  background: royalblue;
}

.bar-erasing.p-progressbar .p-progressbar-value {
  background: red;
}

.bar-writing.p-progressbar .p-progressbar-value {
  background: forestgreen;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
