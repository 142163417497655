export class Logger {

    constructor(prefix = '') {
        this.enabled = getCookie();
        this.prefix = prefix;

        if (prefix !== '') {
            this.prefix = ' > ' + prefix;
        }
    }
    log(data, line = null) {
        if (!this.enabled) {
            return;
        }
        const d = new Date();
        if (line !== null) {
            console.log(d.toISOString() + this.prefix + ' @ ' + line);
        } else {
            console.log(d.toISOString());
        }
        console.log(data);
    }
}

function getCookie() {
    let cookieArr = document.cookie.split(";");
    for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if('dev' === cookiePair[0].trim()) {
            return true;
        }
    }
    return false;
}
